import React from "react";
import "./FeedTimestamping.scss";
import ConfirmationModal from "../../components/ConfirmationModal";
import ShiftSettingsModal from "../../components/ShiftSettingsModal/ShiftSettingsModal";
import StampTimeoutModal from "../../components/StampTimeoutModal";
import ShiftTimeoutModal from "../../components/ShiftTimeoutModal";

import { AuthContext } from '../../context/AuthContext';
import { auth, firestore } from "../../context/FirebaseConfig";
import { signOut } from 'firebase/auth';

import { Link } from "react-router-dom"
import { updateDoc, setDoc, collection, doc, where, query, onSnapshot } from "firebase/firestore";
import { addMinutes } from "date-fns";

// Importing icons
import edit_icon from '../../assets/icons/edit.png';
import accept_icon from '../../assets/icons/checkmark.png';
import correction_icon from '../../assets/icons/menu.png';
import logout_icon from '../../assets/icons/logout.png';
import user_icon from '../../assets/icons/user.png';

export default function FeedTimestamping() {
    const { currentUser } = React.useContext(AuthContext);
    const [ buttonSelection, setButtonSelection ] = React.useState(Array(4).fill({supplier: "", field: "", active: false}));
    const [ shiftInfo, setShiftInfo ] = React.useState({
        "user": "", "feed_shift": "A", "work_shift": "early",
    });
    const [ editMode, setEditMode ] = React.useState(false);
    const [ activeDocument, setActiveDocument ] = React.useState(false);
    const [ confirmLogout, setConfirmLogout ] = React.useState(false);
    const [ settings, setSettings ] = React.useState({});
    const [ supplierLabelset, setSupplierLabelset ] = React.useState({});
    const [ fieldLabelset, setFieldLabelset ] = React.useState({});
    const [ showShiftSettingsModal, setShowShiftSettingsModal ] = React.useState(false);
    const [ showStampTimeoutModal, setShowStampTimeoutModal ] = React.useState(false);
    const [ showShiftTimeoutModal, setShowShiftTimeoutModal ] = React.useState(false);

    // Subscribe to firebase data for live updates
    React.useEffect(() => {
        subscribeToButtonSelection();
        subscribeToActiveDocuments();
        subscribeToBaleFeedSettings();
        subscribeToSupplierLabelset();
        subscribeToFieldLabelset();
    }, []);

    // Set timers for active stamp timeouts
    React.useEffect(() => {
        setShowStampTimeoutModal(false);

        // No active document
        if (!activeDocument || !activeDocument.data?.expiration_timestamp) {
            return;
        }

        const timeToWarning = activeDocument.data.expiration_timestamp.toDate() - new Date();
        const timer = setTimeout(() => {
            setShowStampTimeoutModal(true);
        }, timeToWarning);

        return () => clearTimeout(timer);
    }, [activeDocument]);

    // Set timer for shift ending. Add 15 minutes of margin before triggering warning
    React.useEffect(() => {
        if (!shiftInfo?.shift_expiration_timestamp) {
            return;
        }

        const timeToWarning = addMinutes(shiftInfo.shift_expiration_timestamp.toDate(), 15) - new Date();
        
        const timer = setTimeout(() => {
            setShowShiftTimeoutModal(true);
        }, timeToWarning);
    }, [shiftInfo]);

    function selectionEdited(i, key, value) {
        const newButtonSelection = [...buttonSelection];
        newButtonSelection[i] = {...buttonSelection[i], [key]: value, active: false};
        updateButtonSelection(newButtonSelection);
    }

    function toggleSelection(i) {
        /* Toggle the button at index i. Then trigger the update of the stamp documents */
        const newButtonSelection = buttonSelection.map((selection, selectionI) => {
            return {...selection, active: i===selectionI ? !selection.active : false};
        });
        updateButtonSelection(newButtonSelection);
    }

    function toggleEdit() {
        /* Turn on/off edit mode -> Change the button layout and supplier/fields settings */
        setEditMode((prevMode) => !prevMode);
    }

    function updateButtonSelection(newButtonSelection) {
        // Check for de-activating buttons
        newButtonSelection.forEach((selection, index) => {
            if (!selection.active && buttonSelection[index].active) {
                if (!activeDocument) {
                    console.log("No active document found to close!");
                } else {
                    const activeDocRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps", activeDocument.id);
                    updateDoc(activeDocRef, {end_timestamp: new Date(), active: false});
                }
            }
        })

        // Check for activating buttons
        newButtonSelection.forEach((selection, index) => {
            if (selection.active && !buttonSelection[index].active) {
                const currentDate = new Date();
                const expirationDate = addMinutes(currentDate, settings?.stampMaxDuration || 60);
                const activeDocRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps", currentDate.getTime().toString());
                setDoc(activeDocRef, {
                    supplier: selection.supplier, 
                    field: selection.field,
                    user: currentUser.uid,
                    start_timestamp: currentDate, 
                    expiration_timestamp: expirationDate,
                    active: true,
                    shift_info: shiftInfo,
                });
            }
        })

        const deviceRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        updateDoc(deviceRef, {lastPlantFeedSelection: newButtonSelection});
    }

    function subscribeToButtonSelection() {
        const q = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        onSnapshot(q, (snapshot) => {
            const document = snapshot.data();
            if ("lastPlantFeedSelection" in document) {
                setButtonSelection(document.lastPlantFeedSelection);
            }
            if ("lastPlantFeedShiftInfo" in document) {
                setShiftInfo(document.lastPlantFeedShiftInfo);
            }
        });
    }

    function subscribeToActiveDocuments() {
        const q = query(collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps"),
                where("active", "==", true));
        onSnapshot(q, (snapshot) => {
            const activeDocuments = {};
            snapshot.forEach((document) => {
                activeDocuments[document.id] = document.data();
            });
            if (Object.keys(activeDocuments).length == 0) {
                // No active documents
                setActiveDocument(false);
            } else if (Object.keys(activeDocuments).length == 1) {
                // Single active document
                setActiveDocument({
                    id: Object.keys(activeDocuments)[0],
                    data: Object.values(activeDocuments)[0],
                });
            } else {
                // Multiple active documents -> Error -> Close all
                console.log("Multiple Active Docs!");
                closeAllActiveDocs(Object.keys(activeDocuments));
            }
        });
    }

    function subscribeToBaleFeedSettings() {
        /* Subscribe to document containing settings such as bale-feed-workers names */
        const q = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                return;
            }
            setSettings(snapshot.data())
        });
    }

    function subscribeToSupplierLabelset() {
        const q = doc(firestore, "clients", currentUser.company.company, "labelsets", "supplier");
        onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                return;
            }
            setSupplierLabelset(snapshot.data());
        });
    }

    function subscribeToFieldLabelset() {
        const q = doc(firestore, "clients", currentUser.company.company, "labelsets", "stock_field");
        onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                return;
            }
            setFieldLabelset(snapshot.data());
        });
    }

    async function closeAllActiveDocs(activeDocuments) {
        activeDocuments.forEach((documentId) => {
            const activeDocRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps", documentId);
            updateDoc(activeDocRef, {end_timestamp: new Date(), active: false, error: true});
        })
    }

    function addButton() {
        /** Add a new selection button to the grid. Max number of buttons is 9 */
        if (buttonSelection.length >= 9) {
            return;
        }
        const newButtonSelection = [...buttonSelection, {supplier: "", field: "", active: false}];
        const deviceRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        updateDoc(deviceRef, {lastPlantFeedSelection: newButtonSelection});
    }

    function removeButton() {
        /** Remove the last selection button from the grid. Min number of buttons is 4 */
        if (buttonSelection.length <= 4) {
            return;
        }

        // Check if the removing button is active -> if so deactivate
        if (buttonSelection[buttonSelection.length-1].active) {
            toggleSelection(buttonSelection.length-1);
        }

        const newButtonSelection = [...buttonSelection];
        newButtonSelection.pop();
        const deviceRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id);
        updateDoc(deviceRef, {lastPlantFeedSelection: newButtonSelection});
    }

    function getGridClass() {
        /** Get the naming for the grid-class for the number of selection buttons */
        if (buttonSelection.length <= 4) return 'grid-2x2';
        if (buttonSelection.length <= 6) return 'grid-2x3';
        return 'grid-3x3';
    };

    function extendStampTimeout() {
        /** Extend the stamp timeout */
        if (!activeDocument) {
            return;
        }
        const newExpirationDate = addMinutes(new Date(), settings?.stampMaxDuration || 60);
        const activeDocRef = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps", activeDocument.id);
        updateDoc(activeDocRef, {expiration_timestamp: newExpirationDate});
        setShowStampTimeoutModal(false);
    }

    function deselectAllButtons() {
        /** Deselect all Buttons */
        // Check if there is an activated button. If not, return
        if (!buttonSelection.some((selection) => selection.active)) {
            console.log("No active button found to deselect!");
            return;
        }

        // Deselect all buttons
        const newButtonSelection = buttonSelection.map((selection) => {
            return {...selection, active: false};
        });
        updateButtonSelection(newButtonSelection);
    }

    return (
        <div className="feed-timestamping">
            <div className={`button-container ${getGridClass()}`}>
                {buttonSelection.map((selection, i) => {
                    if (editMode) {
                        return <div key={i} className={`supplier-button ${selection.active ? "active" : ""}`}>
                            <div className="input-field-container">
                                <p>Lieferant</p>
                                <select className="settings-select"
                                    value={selection.supplier}
                                    onChange={(e) => {selectionEdited(i, "supplier", e.target.value)}}
                                >
                                    <option key={-1} value={""}></option>
                                    { Object.entries(supplierLabelset?.labels ?? {})
                                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                                    .map(([key, value]) => {
                                        return <option key={key} value={key}>{value?.full_name}</option>
                                    })}
                                </select>
                                <p>Feld</p>
                                <select className="settings-select"
                                    value={selection.field}
                                    onChange={(e) => {selectionEdited(i, "field", e.target.value)}}
                                >
                                    <option key={-1} value={""}></option>
                                    { Object.entries(fieldLabelset?.labels ?? {})
                                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                                    .map(([key, value]) => {
                                        return <option key={key} value={key}>{value?.full_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    } else {
                        return <button 
                            key={i} className={selection.active ? "supplier-button supplier-button-clickable active" : "supplier-button supplier-button-clickable"} 
                            onClick={() => {toggleSelection(i)}}
                        >
                            <p className="supplier">{supplierLabelset?.labels?.[selection.supplier]?.id ?? selection.supplier}</p>
                            <p className="supplier-name">{supplierLabelset?.labels?.[selection.supplier]?.display_name ?? selection.supplier}</p>
                            <p className="field">Feld {selection.field}</p>
                        </button>
                    }
                })}
            </div>
            {!editMode && <div className="settings-bar">
                <button className="logout-button" onClick={() => setConfirmLogout(true)}>
                    <img src={logout_icon}/>
                </button>
                <button className="setting-button shift-button" onClick={() => {setShowShiftSettingsModal(true)}}>
                    <img src={user_icon}></img>
                </button>
                <Link className="setting-button correction-button" to="/corrections">
                    <img src={correction_icon}></img>     
                </Link>
                <button className="setting-button edit-button" onClick={toggleEdit}>
                    <img src={edit_icon}></img>
                </button>
            </div>}
            {editMode && <div className="settings-bar-edit">
                <button className="add-button" onClick={addButton}>+</button>
                <button className="edit-button" onClick={toggleEdit}>
                    <img src={accept_icon}></img>
                </button>
                <button className="add-button sub-button" onClick={removeButton}>-</button>
            </div>}
            
            {showShiftSettingsModal && <ShiftSettingsModal
                handleClose={() => setShowShiftSettingsModal(false)}
                settings={settings}
            />}

            {confirmLogout && <ConfirmationModal
                message={"Möchten Sie den Nutzer ausloggen?"}
                handleSubmit={() => {setConfirmLogout(false); signOut(auth);}}
                handleClose={() => setConfirmLogout(false)}
            />}

            {showStampTimeoutModal && <StampTimeoutModal
                handleYes={extendStampTimeout}
                handleNo={deselectAllButtons}
                settings={settings}
                selection={activeDocument?.data}
            />}

            {showShiftTimeoutModal && <ShiftTimeoutModal 
                handleClose={() => {setShowShiftTimeoutModal(false); setShowShiftSettingsModal(true);}}
            />}
        </div>
    )
}